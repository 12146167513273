import React from 'react';
import './searchResults.css';
import { Table } from 'antd';
import { InputNumber, Spin, Modal, Row, Col, Empty, Alert } from 'antd';
import { moneyFormat } from '../../utils.js'
import { connect } from "react-redux";
import {
    fetchAddToCart, fetchUpdateQntRichiesta, fetchUpdateQntRichiestaPromo, fetchCrossReference, fetchDeliveryDate,
    saveNoteCliente, fetchUserPromo, fetchCrossReferencePromo, fetchDeliveryDatePromo, fetchSearch
} from "../../actions/actions.js"
import DataColumnUtil from '../SearchResults/dataColumnUtil.js'
import TextArea from 'antd/lib/input/TextArea';
import Promo from '../Promo/promo.js'
import PromoDetail from '../PromoDetail/promoDetail.js'
import { withRouter } from 'react-router-dom'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Player, ControlBar, PlayToggle } from 'video-react';
import "../../video-react.css"

const mapStateToProps = state => {
    return {
        searchResults: state.lastSearch,
        prevSearchResults: state.prevSearch
    };
};
function mapDispatchToProps(dispatch) {
    return {
        fetchAddToCart: (product) => dispatch(fetchAddToCart(product)),

        fetchUpdateQntRichiesta: (product, value) => dispatch(fetchUpdateQntRichiesta(product, value)),
        fetchUpdateQntRichiestaPromo: (product, value, promoId) => dispatch(fetchUpdateQntRichiestaPromo(product, value, promoId)),

        fetchCrossReference: (product) => dispatch(fetchCrossReference(product)),
        fetchCrossReferencePromo: (product, promoId) => dispatch(fetchCrossReferencePromo(product, promoId)),

        fetchDeliveryDate: (product) => dispatch(fetchDeliveryDate(product)),
        fetchDeliveryDatePromo: (product, promoId) => dispatch(fetchDeliveryDatePromo(product, promoId)),

        saveNoteCliente: (product, notaClienteText) => dispatch(saveNoteCliente(product, notaClienteText, "SEARCH")),

        searchSting: (searchString) => dispatch(fetchSearch(searchString))
    };
}

class SearchResults extends React.Component {

    constructor(props) {
        super(props);

        this.onChangeQnt = this.onChangeQnt.bind(this);
        this.onOpenNoteCliente = this.onOpenNoteCliente.bind(this);
        this.onCloseNoteCliente = this.onCloseNoteCliente.bind(this);
        this.onSaveNoteCliente = this.onSaveNoteCliente.bind(this);

        this.renderSearch = this.renderSearch.bind(this);
        this.renderPromoDetail = this.renderPromoDetail.bind(this);
        this.fetchUpdateQntRichiestaPromoAdapter = this.fetchUpdateQntRichiestaPromoAdapter.bind(this);

        this.fetchCrossReferencePromoAdapter = this.fetchCrossReferencePromoAdapter.bind(this);
        this.fetchDeliveryDatePromoAdapter = this.fetchDeliveryDatePromoAdapter.bind(this);

        this.rowCounter = 0;

        this.state = {

            searchIsPending: this.props.searchResults.searchIsPending,
            searchResults: this.props.searchResults.results,
            noResultsFound: this.props.searchResults.noResultsFound,
            prevSearchResults: this.props.prevSearchResults,
            userPromo: this.props.userPromo,
            /* this is a record for which popup will be opened - null if will be close */
            popupNoteCliente: null,
            mode: this.props.mode, /* MODE: SEARCH, PROMODETAIL*/

            popupPromoModal: null,

            /*
            Rows table to be expanded. 
            The Rows key are hardcoded due a bug in antd, see: https://github.com/ant-design/ant-design/issues/10588
            (defaultExpandAllRows only works at the first render)
            So all row are marked to be expanded
            */
            expandedRowKeys: Array.from(Array(5000).keys())

        }

        this.expandable = {
            /* function to render expandable */
            expandedRowRender: (record, index) => {
                if (!record.message) {
                    return null
                }
                else {
                    return (<p style={{ margin: 0, textAlign: "center", textColor: "#ff4d4f" }}><Alert message={record.message} type="success" /> </p>)
                }
            },
            rowExpandable: (record, index) => {
                return (record.message || record.alternative)
            },

            onExpand: (expanded, record) => {
                let newExpandedRowKeys = this.state.expandedRowKeys;
                const index = newExpandedRowKeys.indexOf(record.key);

                if (index > -1) {
                    newExpandedRowKeys.splice(index, 1);
                }
                this.setState({
                    ...this.state,
                    expandedRowKeys: newExpandedRowKeys
                })
            }
        }

        this.rowKey = (record) => this.rowCounter += 1
    }


    fetchUpdateQntRichiestaPromoAdapter(product, value) {

        this.props.fetchUpdateQntRichiestaPromo(product, value, this.state.userPromo.proId);
    }

    fetchCrossReferencePromoAdapter(product, value) {
        this.props.fetchCrossReferencePromo(product, this.state.userPromo.proId)
    }

    fetchDeliveryDatePromoAdapter(product, value) {
        console.log("Delivery Date Promo Adapter ")
        this.props.fetchDeliveryDatePromo(product, this.state.userPromo.proId)
    }


    onChangeQnt(text, record) {
        console.log("Changed quantity to " + text + " on index " + record.key);
    }

    onOpenNoteCliente(record) {
        console.log("Open note popup...")
        this.setState({
            ...this.state,
            popupNoteCliente: record
        })
    }

    onCloseNoteCliente(record) {
        this.setState({
            ...this.state,
            popupNoteCliente: null
        })
    }

    onSaveNoteCliente() {
        this.props.saveNoteCliente(this.state.popupNoteCliente, this.state.popupNoteCliente.noteCliente);

        this.setState({
            ...this.state,
            popupNoteCliente: null
        })

        //console.log(this.refNoteTextArea.current.state.value)
        //this.refNoteTextArea.current.state.value = ""
    }


    /* Manage state update */
    componentDidUpdate(prevProps, prevState, snapshot) {

        console.log("SearchResults: ComponentDidUpdate")

        if (this.props.searchResults !== prevProps.searchResults) {

            console.log("SearchResults: ComponentDidUpdate - SearchResults")

            this.setState({
                ...this.state,
                searchIsPending: this.props.searchResults.searchIsPending,
                prevSearchResults: this.props.prevSearchResults,
                searchResults: this.props.searchResults.results,
                noResultsFound: this.props.searchResults.noResultsFound,
            });
        }

        // Automatically fetch cross reference only if results are available and have changed
        /*
        if (this.props.searchResults.results.length > 0 &&
            prevProps.searchResults.results.length !== this.props.searchResults.results.length) {
            const firstResult = this.props.searchResults.results[0];
            if (firstResult.precodice === "P" || firstResult.precodice === "F") {
                this.props.fetchCrossReference(firstResult); // Fetch for the first result
            }
        }
        */


        if (this.props.userPromo !== prevProps.userPromo ||
            this.props.userPromo.promoProducts !== prevProps.userPromo.promoProducts) {

            console.log("SearchResults: ComponentDidUpdate - UserPromo")

            this.setState({
                ...this.state,
                userPromo: this.props.userPromo
            });
        }
    }

    componentDidMount() {

    }

    render() {

        if (this.state.mode === "PROMODETAIL") {

            return this.renderPromoDetail();
        }
        // DEFAULT 
        else {
            return this.renderSearch();
        }
    }


    renderSearch() {
        let fatto = 0;
        if (this.state.searchIsPending) {

            return (<Spin style={{ marginLeft: "49%", paddingTop: "2em" }} size="large" tip="Ricerca in corso..." />)
        }

        let dtc = new DataColumnUtil();

        let config = {
            onChangeQntRich: this.props.fetchUpdateQntRichiesta,
            onAddToChart: this.props.fetchAddToCart,
            onFindCrossReference: this.props.fetchCrossReference,
            onDeliveryDate: this.props.fetchDeliveryDate,
            onNoteClick: this.onOpenNoteCliente,
            onSearchCode: this.props.searchSting,
        };

        let columns = dtc.createProductTableCol(config);

        let noteClienteText = (this.state.popupNoteCliente !== undefined && this.state.popupNoteCliente !== null &&
            this.state.popupNoteCliente.noteCliente !== undefined) ? this.state.popupNoteCliente.noteCliente : ""

        console.log("noteClienteText vale:" + noteClienteText)
        return (
            <div>
                <div>
                    {
                        /*
                         * NOTE CLIENTE
                         */
                    }
                    <Modal title="Inserisci una nota per l'articolo"
                        visible={this.state.popupNoteCliente !== null} onOk={this.onSaveNoteCliente} onCancel={this.onCloseNoteCliente} >
                        <TextArea maxLength={250} value={noteClienteText}
                            onChange={(e) => {
                                this.setState({
                                    ...this.state,
                                    popupNoteCliente: {
                                        ...this.state.popupNoteCliente || null,
                                        noteCliente: e.target.value
                                    }
                                })
                            }} defaultValue={noteClienteText} ref={this.refNoteTextArea}>
                        </TextArea>
                    </Modal>
                </div>

                {
                    /*
                    * SEARCH RESULT
                    */
                }
                {this.state.searchResults.length > 0 && (
                    <div>
                        <h1 className="searchTitle">Risultati ricerca </h1>
                        <Table className="search-results-table"
                            columns={columns}
                            expandable={this.expandable}
                            dataSource={this.state.searchResults} defaultExpandAllRows={true}
                            /*defaultExpandedRowKeys={this.state.expandList}*/
                            expandedRowKeys={this.state.expandedRowKeys}
                            indentSize={24}
                            childrenColumnName="alternative"
                            rowClassName={(record, index) => { if (record.animationClass !== undefined) { return record.animationClass } }}
                            rowKey={this.rowKey}
                        />
                    </div>
                )}

                {this.state.noResultsFound === true && (
                    <Col xs={24} sm={24} md={8} lg={12} xl={12} style={{ margin: "auto", padding: "5px", textAlign: "center" }} >
                        <Empty image="/icon/icons8-avviso-di-freno-48.png" imageStyle={{ height: 60, }} description={<span style={{ fontSize: "1.75em", fontWeight: "600" }} > Nessun codice è stato trovato </span>}>
                        </Empty>
                        <h2 style={{ fontSize: "1.75em", fontWeight: "600", opacity: "0.65", color: "#000" }} > Ti ricordiamo che puoi inserire nel nostro B2B direttamente i codici originali dei marchi Stellantis ed i codici After Market. Tutti gli altri codici possono essere inseriti nel KStore, guarda il video sotto per scoprire come fare!</h2>

                        <iframe src="https://drive.google.com/file/d/1RYwwOF2N_5zv6fti1JYN6iKbcPMR0U3D/preview" width="800" height="450" allow="autoplay" frameBorder="0"></iframe>

                        {/*<Player preload={"none"} src="https://drive.google.com/file/d/1RYwwOF2N_5zv6fti1JYN6iKbcPMR0U3D/view?usp=sharing"  >
                          <ControlBar autoHide={false} disableDefaultControls={false}>
                             <PlayToggle />
                          </ControlBar>
                </Player>*/}
                    </Col>
                )}

                {this.state.prevSearchResults !== undefined && this.state.prevSearchResults.length > 0 && (
                    <div>
                        <h1 className="searchTitle">Ricerche precedenti</h1>
                        <Table className="search-results-table"
                            columns={columns}
                            expandable={this.expandable}
                            dataSource={this.state.prevSearchResults} defaultExpandAllRows={true}
                            expandedRowKeys={this.state.expandedRowKeys}
                            /*defaultExpandedRowKeys={this.state.expandList}*/
                            indentSize={24}
                            childrenColumnName="alternative"
                            rowClassName={(record, index) => { if (record.animationClass !== undefined) { return record.animationClass } }}
                            rowKey={this.rowKey}
                        />
                    </div>
                )}

                <br />
                <br />
                <br />

                {this.state.searchResults.length === 0 && this.state.userPromo.requestIsPending === false && (

                    <Row className="promoArea" gutter={[32, 32]}>
                        {

                            (this.state.userPromo.data || []).map((promo, index) => {

                                if (promo.proPopup > 0 && (this.state.popupPromoModal === true || this.state.popupPromoModal === null)) {

                                    /*
                                    CHECK IF USER HAVE NEWS TO RAED
                                    */
                                    let key = "PRCTR-" + promo.proId
                                    let keyTime = "PRCTR-" + promo.proId + "-TIME"

                                    let view = localStorage.getItem(key);
                                    let lastTime = localStorage.getItem(keyTime);

                                    if (lastTime === undefined || lastTime === null || lastTime === "") {
                                        lastTime = 0;
                                    }
                                    if (view === undefined || view === null || view === "") {
                                        view = 0;
                                    }

                                    let deltaTime = Date.now() - lastTime

                                    //DON'T SHOW NEWS:
                                    //IF USERS HAS REACHED TOTAL NUMBER OF VIEWS
                                    //IF USERS HAS VIEWED NEWS IN THE LAST 10 MINUTES
                                    //If already shown one window
                                    if ((view < promo.proPopup) && (deltaTime > 600000) && this.state.popupPromoModal !== false && fatto === 0) {

                                        let height = window.screen.height * 0.85
                                        let width = window.screen.width * 0.85

                                        fatto = 1

                                        return (
                                            <Modal width={width} height={height} title="Comunicazione Importante"
                                                visible={true}

                                                onOk={(e) => {
                                                    localStorage.setItem(key, Number(view) + 1);
                                                    localStorage.setItem(keyTime, Date.now());
                                                    this.props.history.push('/promoDetail/' + promo.proId);

                                                }
                                                }
                                                onCancel={(e) => {
                                                    localStorage.setItem(key, Number(view) + 1);
                                                    localStorage.setItem(keyTime, Date.now());
                                                    this.setState({
                                                        ...this.state,
                                                        popupPromoModal: false
                                                    })

                                                }}
                                                okText={"Vai alla pagina"}
                                                cancelText={"Chiudi"}
                                            >


                                                <PromoDetail proId={promo.proId} />





                                            </Modal>

                                        )

                                    }
                                }
                                return (
                                    <Col key={promo.proId} xs={12} sm={12} md={8} lg={6} xl={6}   >
                                        <Promo promo={promo} admin={false} cssStyle="promoSmall" />
                                    </Col>
                                )
                            })}
                    </Row>
                )}

            </div>
        )

    }

    renderPromoDetail() {
        let dtc = new DataColumnUtil();

        let config = {
            onChangeQntRich: this.fetchUpdateQntRichiestaPromoAdapter,
            onAddToChart: this.props.fetchAddToCart,
            onFindCrossReference: this.fetchCrossReferencePromoAdapter,
            onDeliveryDate: this.fetchDeliveryDatePromoAdapter,
            onNoteClick: null,
            onSearchCode: this.props.searchSting,
        };


        let columns = dtc.createProductTableCol(config);
        return (
            <div >
                {this.state.searchResults === undefined && this.state.searchResults.length === 0 && this.state.userPromo.requestIsPending === true && (
                    <Spin style={{ marginLeft: "49%", paddingTop: "2em" }} size="large" tip="Loading promo..." />
                )}


                {this.state.userPromo !== undefined && this.state.userPromo.promoProducts !== undefined &&
                    this.state.userPromo.promoProducts.length > 0 && (
                        <div>
                            <h1 className="searchTitle">Esempi di prodotti in promo <h3>La lista sotto contiene un elenco non esaustivo di prodotti in promo. Scopri gli altri digitando direttamente il codice nella pagina di ricerca</h3> </h1>
                            <Table className="search-results-table"
                                pagination={{ pageSize: 15 }}
                                columns={columns}
                                expandable={this.expandable}
                                dataSource={this.state.userPromo.promoProducts} defaultExpandAllRows={true}
                                expandedRowKeys={this.state.expandedRowKeys}
                                /*defaultExpandedRowKeys={expandList}*/
                                indentSize={24}
                                childrenColumnName="alternative"
                                rowClassName={(record, index) => { if (record.animationClass !== undefined) { return record.animationClass } }}
                                rowKey={this.rowKey}
                            />
                        </div>
                    )}
            </div>
        )
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchResults));