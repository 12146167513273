import { moneyFormat, discountFormat } from "../../utils.js"
import React from 'react';
import { Input, Alert, Tooltip } from 'antd';
import { message, InputNumber, Spin, Popover, Button, Space } from 'antd';
import reactRouter from "react-router";

class DataColumnUtil extends React.Component {

    constructor(props) {
        super(props);

        this.createProductTableCol = this.createProductTableCol.bind(this);
        this.createProductTableColOrder = this.createProductTableColOrder.bind(this);
    }

    createProductTableCol(config) {

        let fakeCallback = function (parameter1, parameter2, parameter3) {
            console.log("DataColumnUtil - Standard Callback");
            console.log("Param1:" + parameter1);
            console.log("Param2:" + parameter2);
            console.log("Param3:" + parameter3);
        }

        let onChangeQntRich = config.onChangeQntRich || undefined
        let onAddToChart = config.onAddToChart || undefined
        let onFindCrossReference = config.onFindCrossReference || undefined
        let onDeliveryDate = config.onDeliveryDate || undefined
        let onNoteClick = config.onNoteClick || undefined
        let onRemoveFromChart = config.onRemoveFromChart || undefined
        let onSearchCode = config.onSearchCode || undefined

        const columns = [
            { title: 'Prec.', dataIndex: 'precodice', key: 'precodice', width: "5%" },
            { title: 'Codice', dataIndex: 'codice', key: 'codice', width: "8%" },
            { title: 'Cod. Sc.', dataIndex: 'codsco', key: 'codsco', width: "5%" },
            { title: 'Fam.', dataIndex: 'fam', key: 'fam', width: "5%" },
            { title: 'Desc.', dataIndex: 'description', key: 'description' },
            {
                title: 'Listino', dataIndex: 'listino', key: 'listino', render: function (text, record, index) {
                    //Il cliente visualizzerà come listino - se il listino è minore del netto
                    if (record.listino === undefined || record.listino < record.netto) {
                        return <span>{"-"}</span>;
                    } else {
                        let l = moneyFormat(record.listino);
                        return (<span>{l}</span>);
                    }
                }
            },
            {
                title: 'Sconto', dataIndex: 'sconto', key: 'sconto', render: function (text, record, index) {
                    //Il cliente visualizzerà come sconto - se lo sconto è minore di 0
                    if (record.listino === undefined || record.sconto < 0) {
                        return <span>{"-"}</span>;
                    } else if (record.sconto !== null && record.sconto !== undefined && record.sconto !== '') {
                        let sconto = discountFormat(record.sconto);
                        return (<span>{sconto}%</span>);
                    } else {
                        return (<span>{record.sconto}</span>);
                    }
                }
            },
            {
                title: 'Netto', dataIndex: 'netto', key: 'netto', render: function (text, record, index) {
                    let n = moneyFormat(record.netto);
                    return (<span>{n}</span>);
                }
            },
            {
                title: 'Info Prod.', dataIndex: 'info', key: 'info', className: 'info', width: "15%", render: function (text, record, index) {
                    let n = discountFormat(record.info);
                    let codSost = record.codiceSostituente
                    let sos = null;
                    let suggested = null;

                    console.log("---->Record is:")
                    console.log(record)

                    if (codSost !== undefined && codSost !== null && codSost !== "") {
                        sos = (
                            <div>
                                <Alert className={"alertCodeMessage"} message={"Cod Sostit. " + codSost} type="warning" showIcon description={<a onClick={() => onSearchCode(codSost)}>Cerca</a>} closable action={<Button size="small" type="primary">Accept</Button>} />
                                <span></span>
                            </div>
                        )
                    }

                    if (record.suggested !== undefined && record.suggested !== null && record.suggested.length > 0) {

                        suggested = (
                            <div>
                                {
                                    record.suggested.map(function (element, index) {

                                        if (index >= 2) {
                                            return
                                        }

                                        if (element.decodingType === "SOSTITUITO") {
                                            return (
                                                <div key={"sost-" + index}>
                                                    <Alert className={"alertCodeMessage"} message={"Risparmia con il codice sostituito disponibile " + element.codice} description={<a onClick={() => onSearchCode(element.codice)}>Cerca</a>} type="success" showIcon closable action={<Button size="small" type="primary">Accept</Button>} />
                                                </div>
                                            )
                                        }
                                        else {
                                            return (
                                                <div key={"alt-" + index}>
                                                    <Alert className={"alertCodeMessage"} message={"Risparmia con il codice rigenerato " + element.codice} description={<a onClick={() => onSearchCode(element.codice)}>Cerca</a>} type="success" showIcon closable action={<div direction="vertical"> <Button size="small" type="primary">Accept</Button> </div>} />
                                                </div>
                                            )
                                        }
                                    })
                                }
                            </div>
                        )
                    }

                    return (<div><span>{n}</span> {sos} {suggested} </div>);
                }
            },

            {
                title: 'Qnt', dataIndex: 'qntRic', key: 'qntRic', render: (text, record, index) => (
                    <InputNumber style={{ width: "5em" }} onChange={(v) => { onChangeQntRich(record, v) }} value={record.qntRichiesta} min={1} max={10000} defaultValue={1} />
                ), width: "6%"
            },
            {
                title: 'Disp.', dataIndex: 'disp.', key: 'disp.', render: function (text, record, index) {
                    let delta = record.disp - (record.qntRichiesta || 1);

                    if (record.IAMAvalaibilityPending === true) {
                        return (<Spin style={{ marginLeft: "1.5em" }} size="small" />)
                    }

                    let icon = {};
                    if (record.disp <= 0 || record.disp === undefined) { //in caso di behalf il codice 0840 fa cresciare il client se non metto istruzione undefined o delta NaN o mettere condizione su riga 147 && stessa
                        icon = (<img style={{ width: "1.2em" }} src="/icon/not-avalaible.png" ></img>)
                    } else if (delta >= 0) {
                        icon = (<img style={{ width: "1.2em" }} src="/icon/avalaible.png" ></img>)
                    } else if (delta < 0) {
                        icon = (<img style={{ width: "1.2em" }} src="/icon/not-all-avalaible.png" ></img>)
                    }

                    if (record.availabilityWarehouse !== undefined && record.availabilityWarehouse !== null && record.primaryWarehouse !== undefined && record.primaryWarehouse !== null) {
                        if (record.primaryWarehouse !== record.availabilityWarehouse) {

                            let dep = {
                                "01": 'SENISE',
                                "03": 'CAGLIARI'
                            };

                            return (
                                <div>
                                    <span>{dep[record.availabilityWarehouse]}</span>
                                    {icon}
                                </div>
                            )
                        }
                    }

                    return icon

                }
            },
            {
                title: 'Azioni', dataIndex: 'azioni', key: 'azioni', width: "15%", render: (text, record, index) => {
                    let delta = record.disp - (record.qntRichiesta || 1);
                    let classIAMButton = record.hideIAMButton === true ? 'hideIamButton' : '';
                    const isOrderable = record.orderable !== false; // Controllo se l'elemento è ordinabile
                    const isQuantityExceeds = (record.qntRichiesta || 1) > record.disp; // Controllo se la quantità supera la disponibilità
                    const isPrecCodeValid = /^[QRS]\d{3}/.test(record.precodice); // Controllo se il precodice inizia con Q, R, S e seguito da 3 numeri

                    return (
                        <div>
                            {onAddToChart != undefined && (
                                <Tooltip title={isQuantityExceeds && isPrecCodeValid ? "La quantità richiesta eccede la quantità disponibile" : ""}>
                                    <img
                                        onClick={() => {
                                            if (isOrderable && !(isQuantityExceeds && isPrecCodeValid)) {
                                                onAddToChart(record);
                                            } else {
                                                message.error("Prodotto non ordinabile");
                                            }
                                        }}
                                        title={isOrderable && !(isQuantityExceeds && isPrecCodeValid) ? "Aggiungi al carrello" : "Non ordinabile"}
                                        style={{
                                            width: "2em",
                                            cursor: isOrderable && !(isQuantityExceeds && isPrecCodeValid) ? "pointer" : "not-allowed",
                                            opacity: isOrderable && !(isQuantityExceeds && isPrecCodeValid) ? 1 : 0.5, // Cambia lo stile per indicare che è disabilitato
                                        }}
                                        src="/icon/icons8-aggiungi-al-carrello-48.png"
                                    />
                                </Tooltip>
                            )}

                            {onRemoveFromChart !== undefined && (
                                <img onClick={() => { onRemoveFromChart(record) }} title="Rimuovi dal carrello" style={{ width: "2em" }} src="/icon/icons8-rimuovi-48.png"></img>)
                            }

                            {
                                (record.noteCliente === undefined || record.noteCliente === null || record.noteCliente === "") && (onNoteClick !== undefined) && (
                                    <img className={classIAMButton} onClick={() => { onNoteClick(record) }} style={{ width: "1.5em" }} title={"Aggiungi una nota"} src="/icon/icons8-nota-48.png"></img>)
                            }
                            {
                                record.noteCliente && (
                                    <img className={classIAMButton} onClick={() => { onNoteClick(record) }} style={{ width: "1.5em" }} title={record.noteCliente} src="/icon/icons8-nota-48-mod.png"></img>)
                            }

                            {(record.IAMSearchPending === undefined || record.IAMSearchPending === false) && onFindCrossReference !== undefined && (
                                <img className={classIAMButton} onClick={() => { onFindCrossReference(record) }} style={{ width: "1.5em" }} title="Trova una cross reference" src="/icon/icons8-ordinamento-frecce-orizzontale-48.png"></img>)
                            }

                            {record.IAMSearchPending === true && (
                                <Spin style={{ marginLeft: "1.5em" }} size="small" />)
                            }
                            {(record.precodice === "P" || record.precodice === "F") && (record.disp <= 0 || delta < 0) && (record.deliveryTimeSearchIsPending === false || record.deliveryTimeSearchIsPending === undefined) && (onDeliveryDate !== undefined) && (
                                <img onClick={() => { onDeliveryDate(record) }} title="Previsione Arrivo" style={{ width: "1.5em" }} src="/icon/icons8-futuro-48.png"></img>
                            )
                            }
                            {(record.precodice === "P" || record.precodice === "F") && (record.disp <= 0 || delta < 0) && record.deliveryTimeSearchIsPending === true && (
                                <Spin style={{ marginLeft: "1.5em" }} size="small" />
                            )
                            }
                            {(record.prezzoCauzione !== undefined) && (
                                <Popover content={"Si applica una cauzione di " + record.prezzoCauzione + " € sul reso del ricambio sostituito"} style={{ width: "10em" }} > <img style={{ width: "1.5em" }} src="/icon/icons8-recycle-48.png"></img></Popover>

                            )
                            }
                        </div>
                    )
                }
            },
        ];
        return columns;
    }

    createProductTableColOrder() {
        const columns = [
            { title: 'Precodice', dataIndex: 'precodice', key: 'precodice' },
            { title: 'Codice', dataIndex: 'codice', key: 'codice' },
            { title: 'Desc.', dataIndex: 'description', key: 'description' },
            {
                title: 'Listino', dataIndex: 'listino', key: 'listino', render: function (text, record, index) {
                    let l = moneyFormat(record.listino);
                    return (<span>{l}</span>);
                }
            },
            {
                title: 'Sconto', dataIndex: 'sconto', key: 'sconto', render: function (text, record, index) {
                    if (record.sconto !== null && record.sconto !== undefined && record.sconto !== '') {
                        let sconto = discountFormat(record.sconto);
                        return (<span>{sconto}%</span>);
                    } else {
                        return (<span>{record.sconto}</span>);
                    }
                }
            },
            {
                title: 'Netto', dataIndex: 'netto', key: 'netto', render: function (text, record, index) {
                    let n = moneyFormat(record.netto);
                    return (<span>{n}</span>);
                }
            },

            { title: 'Info Prod.', dataIndex: 'info', key: 'info', className: 'info' },
            { title: 'Note', dataIndex: 'noteCliente', key: 'noteCliente', className: 'info' },

            {
                title: 'Qnt Ord.', dataIndex: 'qntRichiesta', key: 'qntRic',
            },
            {
                title: 'Qnt Evs.', dataIndex: 'qntEvasa', key: 'qntRic',
            }

        ];
        return columns;
    }

}

export default DataColumnUtil
